// 查询功能商店
import consumer_menuShop_get from '@/lib/data-service/haolv-default/consumer_menuShop_get';
// 更新系统功能商店
import consumer_menuShop_update from '@/lib/data-service/haolv-default/consumer_menuShop_update';

export default {
    data () {
        return {
            loading: false,
            list: []
        }
    },
    methods: {
        get_menu_shop() {
            const __this = this;
            __this.loading = true;
            consumer_menuShop_get().then((res) => {
                __this.list = res.datas;
            }).finally(() => {
                __this.loading = false;
            })
        },
        to_update_add(row) {
            const __this = this;
            const params = {
                ids: row.ids,
                status: 1
            };
            __this.loading = true;
            consumer_menuShop_update(params).then((res) => {
                __this.$message.success('添加成功');
                __this.$store.dispatch('updateNavList', __this.$route); // 更新菜单
                __this.get_menu_shop();
            }).finally(() => {
                __this.loading = false;
            })
        },
        to_update_delete(row) {
            const __this = this;
            const params = {
                ids: row.ids,
                status: 2
            };
            __this.loading = true;
            consumer_menuShop_update(params).then((res) => {
                __this.$message.success('删除成功');
                __this.$store.dispatch('updateNavList', __this.$route); // 更新菜单
                __this.get_menu_shop();
            }).finally(() => {
                __this.loading = false;
            })
        }
    },
    created() {
        const __this = this;
        __this.get_menu_shop();
    },
    mounted () {
    },
    activated () {

    },
    deactivated () {

    }
}